.editSubredditFormContainer {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: 450px;
}

.editSubredditFormTitle {
  /* border: 1px solid red; */
  /* margin: auto; */
  margin-left: 25px;
}

.editSubredditFormTitle span {
  font-size: 20px;
}

.innerEditSubredditFormContainer {
  /* border: 1px solid red; */
  margin: auto;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.subredditEditForm {
  /* border: 1px solid red; */
  width: 90%;
  height: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.editFormInputContainer {
  /* border: 1px solid red; */
  display: flex;
  margin: auto;
  width: 85%;
  flex-direction: column;
  justify-content: center;
}

.editFormInputContainer input {
  width: 90%;
}

.editSubredditButtonDiv {
  display: flex;
  margin: auto;
  margin-top: 5px;
  height: 35px;
  width: 80px;
  margin-left: 60px;
}

.editSubredditButton {
  width: 120px;
  border-radius: 9999px;
  border-style:none ;
  background-color: rgb(1,121,211);
  color: white;
}

.editSubredditButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.editSubredditPreview {
  display: flex;
  /* border: 1px solid red; */
  border: 1px solid rgb(209, 199, 199);
  width: 90%;
  height: 100px;
  margin: auto;
  margin-bottom: 0px;
}

.innerSubredditPreview{
  width: 100%;
}

.editBannerPreview img {
  width: 100%;
  height: 62px;
  object-fit: cover;
}

.editSubredditIconPreview {
  /* border: 1px solid red; */
  position: absolute ;
  display: flex;
  width: 20%;
  height: 10%;
  margin-left: 12px;
  top: 13.5rem;
}

.editIconBackground{
  border-radius: 50%;
  position: absolute;
  /* border: 1px solid red; */
  height: 30px;
  width: 35px;
  background-color:white ;
}

.editIconBackground img {
  border-radius: 50% ;
  height: 29px;
  width: 34px;
  top: .1rem;
  margin-left: .4px;
  position: absolute;
  z-index: 2;
}


.outerEditSubredditPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
  border: 1px solid rgb(209, 199, 199);
  width: 80%;
  height: 230px;
  margin: auto;
  margin-right: 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.editSubredditPreviewTitle span {
  margin-left: 10px;
  text-decoration: underline;
}

.editSubredditPreviewNote {
  /* border: 1px solid red; */
  height: 25px;
  width: 90%;
  margin: auto;
  margin-top: 10px;
}

.editSubredditPreviewNote span {
  font-size: 12px;
  font-weight: bold;
}
