
.rowOneSubreddit {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

}

.rowTwoSubreddit {
  display: flex;
  width: 310px;
  flex-direction: column;
  align-content: flex-end;
  margin-top: 1rem;
  margin-left:20px ;
  margin-right: 10px;
  border-radius: 4px;
}

.subredditButtonDiv {
  /* border: 1px solid red; */
  display: flex;
  margin-left: 10px;
}

.subredditButtonDiv button {
  width: 70px;
  border-radius: 9999px;
  border-style:none ;
  color: white;
  margin-left: 10px;
}

.editSubredditButton {
  background-color: rgb(0,121,211);
}

.editSubredditButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.deleteSubredditButton {
  background-color: rgb(222, 47, 47);
}

.deleteSubredditButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(156, 20, 20);
  cursor: pointer;
}

.subredditBanner {
  display: flex;
  flex-direction: column;
}


.subredditBanner > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.subredditTitle {
  width: 100%;
  position: relative;
  height: 95px;
  background-color: #f2f4f5;
  margin-bottom: 20px;
}

.innerSubredditTitleDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 929px;
  height: 95px;
  margin: auto;
}

.titleDivContent {
  display: flex;
  /* border: 1px solid red; */
  position: absolute;
  height: 100px;
  width: 414px;
  margin-bottom: 14px;
  bottom: .1rem
}

.subredditIcon {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
}

.iconBackground {
  border-radius: 50%;
  width: 122px;
  height: 80px;
  background-color: white;
  /* border: 1px solid red; */

  /* top: 15rem; */
}

.subredditNameDiv{
  display: flex;
  flex-direction: column;
  margin-top: 24px ;
  padding-left: 16px;
  /* border: 1px solid red; */
  height: 80px;
  width: 450px;
}

.bigSubredditName {
  /* border: 1px solid red; */
  display: flex;
  width: 1px;
  justify-content: space-between;
  font-family: verdana,sans-serif;
  font-size: 24px;
  padding-bottom: 5px;
}

.littleSubredditName {
  font-family: verdana,sans-serif;
  font-size: 14px;
}

.subredditInformation {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  background-color: #f2f4f5;
  height: 330px;
  width: 310px;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.aboutSubreddit{
  display: flex;
  align-content: center;
  /* border: 1px solid red; */
  height: 50px;
  width: 100%;
  background-color: rgb(0,121,211);
  border-radius: 2px;
}

.aboutSubreddit > span {
  align-self: center;
  padding-left: 15px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  font-weight: 500;
  color: #f2f4f5;
}

/* .homepageSubredditContent {
  display: flex;
} */

.subredditDescriptionDiv {
  /* border: 1px solid red; */
  height: 130px;
  width: 288=7px;
  padding: 12px;
}

.subredditDescription {
  font-size: 14px;
  font-family: verdana,sans-serif;
  word-wrap: break-word;
}

.subredditCreatePostDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  height: 120px;
}

.createSubredditPost {
  display: flex;
  align-items: center;
  font-family: verdana, sans-serif;
  font-size: 14px;
  justify-content: center;
  /* border: 1px solid red; */
  border-radius: 9999px;
  width: 290px;
  height: 40px;
  color: #f2f4f5;
  background-color: rgb(0,121,211);
}

.createSubredditPost:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.joinToggleSubredditButton {
  border-radius: 9999px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  font-family: verdana, sans-serif;
  font-size: 14px;
  justify-content: center;
  color: #f2f4f5;
  border-style: none;
  background-color: rgb(0,121,211);
}

.joinToggleSubredditButton:hover{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.loadingState {
  margin-top: 5rem;
}
