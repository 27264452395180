.userProfileInfo {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  background-color: #f2f4f5;
  height: 380px;
  width: 320px;
  margin-top: 1.34rem;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.userProfileBanner {
  /* border: 1px solid red; */
  height: 95px;
  background-color: rgb(0,121,211);
  border-radius: 4px;

}

.profileInfodDiv {
  border: 1px solid rgb(242, 234, 234);
  height: 100px;
  width: 118px;
  top: 8rem;
  margin-left: 20px;
  background-color:  #f2f4f5;
  position: absolute;
}

.profileInfodDiv > img {
  height: 99px;
  width: 118px;
}

.profileInfodDiv > span {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
  margin-left: 5px;
}

.noPostsYetDiv {
  display: flex;
  flex-direction: row;
  width: 600px;
  align-items: flex-start;
  justify-content: center;

  border: 1px solid rgb(206, 195, 195);
  margin-bottom: 1.5rem;
  background-color: rgb(247, 247, 252);
  border-radius: 2px;
  margin-top: 1.23rem;
}

.noPostsYetDiv > span {
  font-size: 22px;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.innerProfileInfoDiv {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
}

.profileCreatePostDiv {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
  /* margin: auto; */
}


.editDeletePostButtonDiv {
  display: flex;
}

.editDeletePostButtonDiv button{
  display: flex;
  height: 20px;
  width: 80px;
  align-items: center;
  font-family: verdana, sans-serif;
  font-size: 14px;
  justify-content: center;
  color: #f2f4f5;
  border-style: none;
}


.editPostButton {
  background-color: rgb(0,121,211);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.editPostButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.deletePostButton {
  background-color: rgb(222, 47, 47);
}

.deletePostButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(156, 20, 20);
  cursor: pointer;
}


.followedSubredditInfo {
  height: 350px;
  width: 320px;
  border: 1px solid rgb(206, 195, 195);
  background-color: #f2f4f5;
  border-radius: 4px;
  overflow-y: scroll hidden;
  margin-top: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.aboutFollowedSubreddit{
  display: flex;
  align-content: center;
  /* border: 1px solid red; */
  height: 50px;
  width: 100%;
  background-color: rgb(0,121,211);
  border-radius: 2px;
}

.aboutFollowedSubreddit > span {
  align-self: center;
  padding-left: 15px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  font-weight: 500;
  color: #f2f4f5;
}

.followedSubredditContent {
  overflow-y: scroll;
  height: 250px;
}

.followedSubredditDescriptionDiv {
  display: flex;
  flex-direction: row;
  align-content: center;
  /* border: 1px solid red; */
  height: 40px;
  width: 288=7px;
  padding: 12px;
}

.followedSubredditDescriptionDiv:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
}

.followedSubredditDescription {
  display: flex;
  align-content: center;
  height: 50px;
}


.followedubredditName {
  padding-left: 15px;
  align-self: center;
  font-size: 17px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
}


.cakeDay{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-top: 7rem;
  padding-left: 1.5rem;
  margin: auto;
  /* border: 1px solid red; */
  width: 100%;
  height: 50px;
}

.cakeDaySpan {
 font-size: 14px;
 font-weight: bold;
}
