#navBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500px;
  height: 49px;
  border-bottom: 1px solid rgb(216, 203, 203);
  /* margin-bottom: 2rem; */
}

#navBarContainer {
  position: fixed;
  top: 0px;
  background-color: white;
  width: 100%;
  z-index: 2;
  /* margin-left: 8%;
  margin-right: 8%; */
}


#rightNav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  height: 100%;
  position: relative;
}

#logo_div {
  height: 100%;
  width: 12%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  /* justify-content: center; */

}

#logo_div>a>img{
  height: 100%;
  width: 100px;
}



.user_icon>img {
  width: 35px;
  height: 30px;
  margin-top: 4px;
  border-radius: 50%;
}

.menu_drop > span {
  padding-left: 5px;
  margin: auto;
  font-size: 14px;
  font-family: verdana, sans-serif;
  color: rgb(43, 40, 40);
  align-items: center;
}

.profileDropdownDiv {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  padding: 5px;
  margin-right: 1rem;
  border-radius: 4px;
  height: 25px;
  /* border: 1px solid red; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.profileDropdownDiv:hover {
  cursor: pointer;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.profileDropdownDiv:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.profileDropdown {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  align-items: center;

}


.menu_drop {
  display: flex;
  margin: auto;
  align-items: center;
  color: #595959;
  font-size: 17px;
  padding-left: 5px;
  padding-right: 10px;
  width: 100%;
  height: 100%;
}


#menu {
  cursor: pointer;
  position: absolute;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  z-index: 2;
  top: 60px;
  right: 20px;
  width: 200px;
  height: 165px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


#menu>* {
  padding: 15px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: .5px;
}

#menu>*:hover:not(.loggedInUser) {
  text-shadow: 2px 2px 4px #5c5757;
}

.profilePageDropdown {
  text-decoration: none;
  color: black;
}

.loggedInUser {
  border-bottom: .2px solid #5959593a;
}
.loggedInUser:hover {
  cursor: default;
}


.signInButton {
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  height: 33px;
  border-radius: 9999px;
  border-style:none ;
  color: white;
  margin-left: 10px;
  margin-right: 12px;
  background-color: rgb(0,121,211);
}

.signInButton:hover{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.signInButton:active{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  cursor: pointer;
}

.signUpButton {
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  height: 33px;
  border-radius: 9999px;
  border-style:none ;
  color: rgb(0,121,211);
  border: 2px solid rgb(0,121,211);
  margin-left: 10px;
  background-color: rgb(248,250,254);
}

.signUpButton:hover{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  cursor: pointer;
}

.signUpButton:active{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
