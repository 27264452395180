.darkmodeToggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding-top: .5rem;
  color: rgb(133, 133, 154);
  outline: none;
  transition: color 0.3s ease;
}

.darkmodeToggle:hover {
  color: #a0a0a0;
}
