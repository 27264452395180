.outerPostDetailContainer {
  width: 850px;

}

.postDetailContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  /* border: 1px solid lightgray; */
}

.postDetailImage{
  width: 750px;
  height: 630px;
  padding-bottom: 2rem;
}


.postPageAboutSubreddit {
  display: flex;
  align-content: center;
  /* border: 1px solid red; */
  height: 35px;
  width: 310px;
  background-color: rgb(161, 162, 180);
}

.postPageAboutSubreddit > img {
  border-radius: 4px;
  max-width: 310px;
}

.postDescriptionSubredditLogo {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  height: 80px;
  padding: 12px
}

.subredditLgo {
  border-radius: 50%;
  height: 50px;
  max-width: 55px;
  margin-right: 8px;
}


.postDescriptionSubredditLogo > span {
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.postDescriptionSubredditLogo:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; ;
}

.goBackHome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* border: 1px solid red; */
  width: 100%;
  height: 40px;
  color: white;
  background-color: rgb(0,121,211);
  border-radius: 4px;
}

.goBackHome span {
  text-decoration: none;

}

.goBackHome:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.goBackHome:active {
  box-shadow:  rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
