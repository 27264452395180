.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 515px;
  width: 350px;
  padding:12px;

}

.loginLogoDiv {
  /* border: 1px solid red; */
  margin: auto;
}

.loginWelcome {
  /* border: 1px solid red; */
  margin-bottom: 20px;
}

.loginWelcome > span {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
}

.innerLoginFormContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding: 12px;
  margin: auto;
}

.loginForm {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}


.loginEmailDiv {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  /* border: 1px solid red; */
  width: 100%;
  height: 30px;
  margin-bottom: 12px;
}

.loginEmailInput {
  width: 90%;
}

.loginPasswordDiv {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  /* border: 1px solid red; */
  width: 100%;
  height: 30px;
  margin-bottom: 12px;
}

.loginPasswordInput {
  width: 90%;
}

.loginButtonsDiv{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55%;
}

.loginDemoButtons {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.loginDemoButtons > button {
  height: 100%;
  width: 40%;
}

.loginSignUpDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
  /* border: 1px solid red; */
  padding-top: 10px;
  align-items: center;
  margin: auto;
}

.modalLoginButton {
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  height: 33px;
  border-radius: 9999px;
  border-style:none ;
  color: white;
  margin-left: 10px;
  margin-right: 12px;
  background-color: rgb(0,121,211);
}

.modalLoginButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.demoButton {
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  height: 33px;
  border-radius: 9999px;
  border-style:none ;
  color: white;
  margin-left: 10px;
  margin-right: 12px;
  background-color: rgb(73, 130, 174);
}

.demoButton:hover{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.loginSignUpDiv span {
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.loginSignUpButton {
  margin-top: 5px;
  width: 80px;
  border-radius: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.loginSignUpButton{
  border-color: #3498db;
  color: #fff;
  box-shadow: 0 0 40px 40px #3498db inset, 0 0 0 0 #3498db;
}

.loginSignUpButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}
.loginSignUpButton:active {
  box-shadow:  rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
