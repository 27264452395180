.editPostFormPageContainer {
  border: 1px solid red;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  width: 80%;
  max-width: 1100px;
  margin: auto;
  margin-top: 7rem;
  padding: 20px;
  border: 1px solid rgb(206, 195, 195);
  background-color:  white;
  border-radius: 4px;
}

.innerEditPostFormPageContainer {
  width: 90%;
  margin: auto;
  height: 90%;
}

.editPostFormContainer {
  display: flex;
  padding: 20px;
  margin-top: 5rem;
  flex-direction: column;
  /* border: 1px solid red; */
  margin: auto;
  width: 90%;
  height: 50%;
}

.editFormTitle {
  font-size: 18px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  border-bottom: .5 solid lightgrey ;
}

.innerPostEditContainer {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  border: 1px solid rgb(206, 195, 195);
  padding: 12px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


.innerPostEditContainer span {
  font-size: 15px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  color: rgba(0, 0, 0, 0.565);
}

.editPostFormButton {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.editPostFormButton button {
  color: white;
  background-color: rgb(0,121,211);
  border-style: none;
  height: 30px;
  border-radius: 9999px;
  width: 90px;
}
.editPostFormButton button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;;
  background-color: rgb(5, 83, 142);
  cursor: pointer;
}

.editPostFormButton button:active {
  box-shadow:  rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.createTitleInputBox {
  margin-top: 5px;
}

.editPreviewContainer {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  width: 100%;
  height: 55%;
  padding: 12px;
  /* margin: auto; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.editPreviewSpanDiv span {
  margin-left: 3rem ;
  font-size: 16px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  text-decoration: underline;

}
.editPreviewSpanDiv {
  /* border: 1px solid red; */
  width: 100%;
  height:5%
}

.editPreviewContent {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  height: 85%;
  padding: 12px;
}


.editPreviewTitle {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  height: 15%;
  margin-bottom: 10px;
}

.editPreviewTitle span {
  font-size: 20px;
  font-family: Avenir Next, Avenir, SegoeUI, sans-serif;
  word-break: break-all;
  overflow-y: scroll;
  overflow: hidden;

}

.editPreviewText {
  display: flex;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 10px;
  word-break: break-all;
  overflow-y: scroll;
  overflow: hidden;
}

.editPreviewImage {
  display: flex;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

}

.editPreviewImage img {
  width: 100%;
  height: 93%;
}

.editPreviewLink {
  display: flex;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 10px;
}
