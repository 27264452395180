 /*sign up form pag */
 .signUpFormPageContainer {
   /* border: 1px solid red; */
   display: flex;
   flex-direction: row;
   /* align-items: center; */
   justify-content: center;
   width: 80%;
   max-width: 1100px;
   margin: auto;
   margin-top: 14rem;

   /* border: 1px solid rgb(206, 195, 195); */
 }


 .signUpFormContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 60%;
   width: 40%;
   padding: 12px;
   margin-right: 20px;
   background-color: white;
   border-radius: 4px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   /* margin: auto; */
   /* border: 1px solid red; */
 }

 .signUpFormRules {
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 }

 .signUpLogoDiv {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: auto;
 }

 .signUpLogoDiv img {
   width: 100px;
   height: 100px;
 }

 .registerSpan {
   padding: 12px;
   font-weight: 500;
 }

 .innerSignUpFormContainer {
   display: flex;
   justify-content: center;
   width: 90%;
   padding: 12px;
   margin: auto;
 }


 .signUpForm {
   display: flex;
   flex-direction: column;
   width: 90%;
 }


 .signUpUsernameDiv {
   /* display: flex; */
   /* justify-content: center; */
   /* border: 1px solid red; */
   width: 100%;
   height: 40px;
   margin-bottom: 12px;
 }


 .signUpFormInput {
   width: 90%;
   margin-bottom: 10px;
 }

 .errorDiv {
   margin-bottom: 15px;
   color: red;
 }

 .createAccountDiv {
   display: flex;
   /* justify-content: center; */
   margin-top: 12px;
 }

 .createAccountDiv button {
   height: 40px;
   width: 150px;
   margin-left: 6rem;
 }
